import saveAs from 'save-as'
import { extractFromLocal } from './loadSave';
// import html2pdf from 'html2pdf.js';

export function downloadToExcel(oldStan, targetStan, settings4stan, status) {
    status('Preparing download, please be patient..');
    var XLSX = require("xlsx");
    // Convert table to a workbook
    var alteredOldElement = oldStan.current.cloneNode(true);
    alteredOldElement.querySelectorAll('.hidden').forEach(e => e.remove());

    var alteredTargetElement = targetStan.current.cloneNode(true);
    alteredTargetElement.querySelectorAll('.hidden').forEach(e => e.remove());

    var alteredSettingsElement = settings4stan.current.cloneNode(true);
    alteredSettingsElement.querySelectorAll('.hidden').forEach(e => e.remove());

    var old = XLSX.utils.table_to_sheet(alteredOldElement);
    var target = XLSX.utils.table_to_sheet(alteredTargetElement);
    var settings = XLSX.utils.table_to_sheet(alteredSettingsElement);

    var wb = XLSX.utils.book_new();
    
    // Convert workbook to binary
    XLSX.utils.book_append_sheet(wb, old, "Old Standard");
    XLSX.utils.book_append_sheet(wb, target, "Target Standard");
    XLSX.utils.book_append_sheet(wb, settings, "Settings");
    
    var wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary', append: true});
    
    // Trigger a download
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'download.xlsx')
    setTimeout(() => status(""), 3000)
};

export function downloadToPDF(oldStan, targetStan, settings4stan, fileName, status) {
    status('Preparing download, please be patient..')
    
    var html2pdf = require("html2pdf.js");

    var elementToPrint = document.createElement('div');
    var lineBreak1 = document.createElement('br');
    var standardTitle = document.createElement('div');
    standardTitle.innerText="Standard Formulary Projections";
    standardTitle.classList.add("text-2xl", "font-bold", "mb-4");
    oldStan.current.classList.add("break-after-all");
    elementToPrint.appendChild(standardTitle);
    elementToPrint.appendChild(oldStan.current.cloneNode(true));
    elementToPrint.appendChild(lineBreak1);
    
    var targetTitle = document.createElement('div');
    targetTitle.innerText="Target Formulary Projections";
    targetTitle.classList.add("text-2xl", "font-bold", "mb-4");
    elementToPrint.appendChild(targetTitle);
    elementToPrint.appendChild(targetStan.current.cloneNode(true));
    var lineBreak2 = document.createElement('br');
    elementToPrint.appendChild(lineBreak2);

    var alteredElement = settings4stan.current.cloneNode(true);
    alteredElement.classList.remove('hidden');
    elementToPrint.appendChild(alteredElement);

    var options = {
        margin:       0.25,
        filename:     `${fileName}-${new Date().toLocaleString().split(' ').join('').split(',')[0]}-${(new Date().toLocaleString().split(' ').join('').split(',')[1]).split('_').join(':')}.pdf`,
        image: { type: "jpeg", quality: 1 },
        pagebreak: { before: "br", padding: 200 },
        html2canvas: { scale: 4, useCORS: true, dpi: 192, letterRendering: true },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
    setTimeout(() =>html2pdf().from(elementToPrint).set(options).save().then(() => status("")));
};

export function downloadToPDFGlobal(file, fileName, formulary, orientation, breaker, elementWidth, elementHeight, status) {
    
    status('Preparing download, please be patient..')
    
    var html2pdf = require("html2pdf.js");

    var elementToPrint = document.createElement('div');
    var standardTitle = document.createElement('div');
    if (formulary) {
        if (fileName === 'credit') {
            standardTitle.innerText=fileName+" Forcasting";
            
        } else {
            standardTitle.innerText=fileName+" Formulary Projections";
        }
    } else {
        standardTitle.innerText=fileName;
    };
    standardTitle.classList.add("text-2xl", "font-bold", "mb-4", "capitalize");
    // file.current.classList.add("break-after-all");
    // file.current.classList.remove("hidden");
    elementToPrint.appendChild(standardTitle);
    elementToPrint.appendChild(file.current.cloneNode(true));
    // elementToPrint.appendChild(lineBreak1);

    

    var options = {}

    if (orientation==="landscape") {
        options = {
            margin:       10,
            filename:     `${fileName}-${new Date().toLocaleString().split(' ').join('').split(',')[0]}-${(new Date().toLocaleString().split(' ').join('').split(',')[1]).split('_').join(':')}.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 4, scrollY: 0, useCORS: true, dpi: 300, letterRendering: true, width: 1920, height: 1080 },
            jsPDF:        { unit: 'px', orientation: orientation?orientation:'portrait', format: [1920, 1080] }
        };
    } else {
        options = {
            margin:       .25,
            filename:     `${fileName}-${new Date().toLocaleString().split(' ').join('').split(',')[0]}-${(new Date().toLocaleString().split(' ').join('').split(',')[1]).split('_').join(':')}.pdf`,
            image: { type: "jpeg", quality: 1 },
            pagebreak: { mode: "CSS", before: "br" },
            html2canvas: { scale: 4, useCORS: true, dpi: 300, letterRendering: true },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
          };

    }


    // if (orientation) {
    //     options = {
    //         margin:       10,
    //         filename:     `${fileName}-${new Date().toLocaleString().split(' ').join('').split(',')[0]}-${(new Date().toLocaleString().split(' ').join('').split(',')[1]).split('_').join(':')}.pdf`,
    //         image: { type: "jpeg", quality: 1 },
    //         pagebreak: breaker?breaker:{ after: "table" },
    //         html2canvas: { scale: 4, useCORS: true, dpi: 300, letterRendering: true, width: elementWidth+1500, height: elementHeight+500 },
    //         jsPDF:        { unit: 'px', format: 'letter', orientation: orientation?orientation:'portrait', format: [elementWidth, elementHeight] }
    //     };
    // } else {
    //     options = {
    //         margin:       .10,
    //         filename:     `${fileName}-${new Date().toLocaleString().split(' ').join('').split(',')[0]}-${(new Date().toLocaleString().split(' ').join('').split(',')[1]).split('_').join(':')}.pdf`,
    //         image: { type: "jpeg", quality: 1 },
    //         pagebreak: breaker?breaker:{ after: "table" },
    //         html2canvas: { scale: 2, useCORS: true, dpi: 300, letterRendering: true },
    //         jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    //       };

    // };

    setTimeout(() => html2pdf().from(elementToPrint).set(options).save().then(() => status("")), 100);
};


export function downloadCrystalBallLandscape(file, fileName, formulary,  breaker, elementWidth, elementHeight, status) {
    
    status('Preparing download, please be patient..')
    
    var html2pdf = require("html2pdf.js");

    var elementToPrint = document.createElement('div');
    var standardTitle = document.createElement('div');
    if (formulary) {
        standardTitle.innerText=fileName+" Formulary Projections";
    } else {
        standardTitle.innerText=fileName;
    };
    standardTitle.classList.add("text-2xl", "font-bold", "mb-4", "capitalize");

    // console.log(file.current.mainTable)
    // file.current.classList.add("break-after-all");
    // file.current.classList.remove("hidden");
    
        elementToPrint.appendChild(standardTitle);
        elementToPrint.appendChild(file.current.mainTable.cloneNode(true));

        elementToPrint.querySelectorAll('input').forEach(e => {
            
            const spanELement = document.createElement('span');
            spanELement.textContent = e.value
            spanELement.classList = "text-white"
            
            e.parentNode.replaceChild(spanELement, e)
        })

        // console.log(elementToPrint)


    elementToPrint.appendChild(file.current.sharesTables.cloneNode(true));
    elementToPrint.appendChild(file.current.breakevenTable.cloneNode(true));
    // elementToPrint.appendChild(lineBreak1);

    var options = {}

    options = {
        margin:       10,
        filename:     `${fileName}-${new Date().toLocaleString().split(' ').join('').split(',')[0]}-${(new Date().toLocaleString().split(' ').join('').split(',')[1]).split('_').join(':')}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 1, scrollY: 0, useCORS: true, dpi: 300, letterRendering: true, width: elementWidth+350, height: elementHeight+2500 },
        jsPDF:        { unit: 'px', orientation: 'landscape', format: [elementWidth, elementHeight] }
    };

    setTimeout(() => html2pdf().from(elementToPrint).set(options).save().then(() => status("")), 100);
};

export async function copyToClipboard(content) {

    await navigator.clipboard.writeText(content)
    
    try {
        await navigator.clipboard.readText();
        alert('Content copied to clipboard!');
    } catch (err) {
        console.error('Unable to copy', err);
        alert('Unable to copy');
    };
};

export function downloadToExcelGlobal(event, status) {
    status('Preparing download, please be patient..');
    var XLSX = require("xlsx");
    // Convert table to a workbook
    var alteredElement = event.current.cloneNode(true);
    alteredElement.querySelectorAll('.hidden').forEach(e => e.remove());

    var old = XLSX.utils.table_to_sheet(alteredElement);

    var wb = XLSX.utils.book_new();
    
    // Convert workbook to binary
    XLSX.utils.book_append_sheet(wb, old, "Events");
    
    var wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary', append: true});
    
    // Trigger a download
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'download.xlsx')
    setTimeout(() => status(""), 3000)
};

// helper functions

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
};

export async function downloadOptimized(casenumber, set) {
    try {
        set(previous => ({
            ...previous,
            loading: true
        }));

        const response = await fetch(`/api/download_optimized/${casenumber}`, {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });

        if (!response.ok) {
            throw new Error(`Download request failed with status ${response.status}`);
        }

        // 1. Get the file as a blob
        const data = await response.blob();

        // 2. Create an object URL for the blob
        const downloadUrl = window.URL.createObjectURL(data);

        // 3. Create a temporary <a> element to trigger the download
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = downloadUrl;
        link.download = `${casenumber}.xlsx`;    // <-- Excel file extension
        document.body.appendChild(link);

        // 4. Trigger the download
        link.click();

        // 5. Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);

        set(previous => ({
            ...previous,
            loading: false
        }));
    } catch (err) {
        set(previous => ({
            ...previous,
            loading: false
        }));
        console.error(err);
    }
};
