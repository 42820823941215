import {extractFromLocal} from "./loadSave";

export async function createFormulary ({formularyId, baseModel}, addData) {
    if (formularyId && baseModel) {
        try {
            const response = await fetch("/api/create_formulary", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${extractFromLocal().accessToken}`,
                },
                body: JSON.stringify({
                    formulary_id: formularyId,
                    base_model: baseModel
                })
            });
            const data = await response.json();

            if (data.status === 'success') {
                alert(data.message);


                // fetchAndLoadFormulary(addData)
                addData(data => ([
                    ...data,
                    {
                        biosimilar_position:"", 
                        client: "",
                        description: "",
                        formularyid: formularyId,
                        vendor: "",
                    }
                ]))
            
            } else {
                alert("Error: " + data.message);
            }
        } catch (error) {
            console.error("Error creating formulary:", error);
            alert("Failed to create formulary. Check the console for more details.");
        }
    } else {
        alert('Please select a base model and enter a new formulary name.');
    }
};

export async function importFormularyRate(formname, importedData, setLoading, errorMessage, activate, toggler, context ){
    try {
        
        const formData = new FormData();
        formData.append("csvfile", importedData);
        
        const data = await fetch(`/api/importFormularyRateFile/${formname}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: formData
        });

        let response = await data.json();

        if (data.ok) {
            alert(response.message);
            activate("importFormulary", toggler.toggle, toggler.setToggle, false);
            context.addFormularies([
                ...context.formularies,
                {
                    "formularyid": formname,
                    "description": "",
                    "client": "",
                    "vendor": "",
                    "biosimilar_position": ""
                }
            ]);
        } else {
            errorMessage(response.detail);
        }
        setLoading(false);


    } catch(err) {
        errorMessage(err);
    };
};

export function formatBytes(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}`}


export async function copyFormulary(source, destination, setLoading, close){
    setLoading(data => ({
        ...data,
        loading: true
    }));
    try {
        const data = await fetch(`/api/copy_formulary/?source_formulary_id=${source}&destination_orgid=${destination}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });

        let response = await data.json();

        if (data.ok) {
            alert(response.message)
            setLoading(data => ({
                ...data,
                loading: false
            }));       
            close();    
        } 

    } catch(err) {
        console.log(err);
        setLoading(data => ({
            ...data,
            loading: false
        })); 
    };
};

export async function getFormularyMetadata(set){
    try {
        const data = await fetch(`/api/get_formulary_metadata`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });

        let response = await data.json();

        if (data.ok) {
            // console.log(response)
            set(response)
        } 

    } catch(err) {
        console.log(err);
    };
};

export async function updateFormularyMetadata(payload, currentFormularies, addFormulary) {
    try {
        const data = await fetch(`/api/update_formulary_metadata`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: JSON.stringify(payload)
        });
        const response = await data.json()
        if(data.ok) {
            console.log(response)
            var current = [...currentFormularies];
            addFormulary(() => ([
                ...current.map(ele => ele.formularyid === payload.formularyid?payload:ele)
            ]));
            
        };
        
    } catch (err) {
        console.log("Error:", err)
    };
};
