import React from 'react';
import PopUpSection from '../Section/PopUpSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { mergeObjects } from '../../utils/globalHandlers';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';
import { matchLobConstrained } from '../../utils/revenueCycleManagement';

export default function ConstraintBuilder({pageData, formData, fields, fileSelector, setStats, setLoading, constraintBuilder, toggleCB, stats, constrain, constraint}) {
    // const ruleBook = {
    //     "Date of Fill": "date_of_fill",
    // };
    const dropDownStyle = "border py-3 px-4 w-full ";
    const buttonStyles = 'w-full bg-light-blue py-3 rounded-full mt-4 text-white font-bold hover:bg-hover-blue';

    // function chooseRule(title) {
    //     constrain(data => ({
    //         ...data,
    //         setting: {
    //             ...data.setting,
    //             rule: title
    //         }
    //     }));
    // };
    function chooseConstraints(e) {
        const { name, value } = e.target;
        constrain(data => ({
            ...data,
            setting : {
                ...data.setting,
                [name]: value
            },
        }));
    };
    function tempAddRule(e) {
        e.preventDefault();
        constrain(data => ({
            ...data,
            temp: {
                ...data.temp, 
                [constraint.setting.type]: data.temp[constraint.setting.type]?
                [
                    ...data.temp[constraint.setting.type], 
                    {...data.setting}
                ]:
                [
                    {...data.setting}
                ]
            },
            setting: {
                rule: "",
                type: "",
                choice: "",
                operator: "",
                freeform: "",
            },
        }));
    };
    function resetAll() {
        constrain(() => ({
            temp: {},
            rules: {},
            setting: {
                rule: "",
                type: "",
                choice: "",
                operator: "",
                freeform: "",
            },
        }));
    };
    function clearChoices() {
        constrain(data => ({
            ...data,
            // temp: {},
            setting: {
                type: "",
                operator: "",
                freeform: "",
            },
        }));
    };
    function clearTemp() {
        constrain(data => ({
            ...data,
            temp: {},
        }));
    };
    function saveChoices(e){
        e.preventDefault();
        if (Object.keys(constraint.temp).length>0) {
            try {
                toggleCB(!constraintBuilder);
                const rules = {
                    ...constraint.temp,
                    ...constraint.rules,
                };
                const reply = matchLobConstrained(formData, fields, fileSelector, setStats, setLoading, rules);
                if (reply) {
                    constrain(data => ({
                        ...data,
                        rules: {
                            ...mergeObjects(constraint.rules, constraint.temp)
                        },
                        temp: {}
                    }));
                }
            } catch (err) {
                console.log(err)
            } 
        }
    };
    function closeBuilder() {
        toggleCB(!constraintBuilder);
        clearChoices();
    };

    HandleKeydownEscape(closeBuilder);

    const headerStyles = "font-bold underline underline-offset-4 mb-1"
    const uLStyles = "flex"
    const listContainer = "mb-6 border rounded p-2 min-h-[100px]"
    
    return (
        <>
            <PopUpSection>
                <form onSubmit={tempAddRule} className='relative min-h-[650px] w-[1100px] bg-white mx-auto rounded-lg shadow-xl px-14 py-20'>
                    <div className='border p-4 mb-6 rounded'>
                        <div className={headerStyles}>To add a new rule: </div>
                        <div>1. Select from the dropdowns below the column to constrain, an operator, and the value</div>
                        <div>2. Click the '+' button to add each rule</div>
                        <div>3. Select 'save' to re-run process with added constraints</div>
                    </div>
                    <button className='absolute right-4 top-4' type='button' onClick={closeBuilder}>
                        <FontAwesomeIcon className='hover:rotate-[180deg] duration-500' icon={faXmark}/>
                    </button>
                    {stats.success?<>
                        <div className={listContainer}>
                            <div className={headerStyles}>Constraints to add (unsaved):</div>
                            {Object.keys(constraint.temp).map((section, index) => (
                                <ul key={`${index}: ${section}`} className={uLStyles}>
                                    <li>{section}</li>
                                    {(constraint.temp[section]).map((rules, ind) => (
                                        <li key={`${index}-${ind}: ${rules.choice} $${rules.operator} $${rules.freeform}`}>
                                            <div className='border'>
                                                <span className='pr-3'>+</span>
                                                <span className='pr-2'>{`${rules.rule}:`}</span>
                                                <span>{rules.choice}</span>
                                                <span>{rules.operator}</span>
                                                <span>{rules.freeform}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ))}

                        </div>
                        <div className={listContainer}>
                            <div className={headerStyles}>Current constraints:</div>
                            {Object.keys(constraint.rules).map((section, index) => (
                                <ul key={`${index}: ${section}`} className={uLStyles}>
                                    <li>{section}</li>
                                    {(constraint.rules[section]).map((rules, ind) => (
                                        <li key={`${index}-${ind}: ${rules.choice} $${rules.operator} $${rules.freeform}`}>
                                            <div>
                                                <span className='pr-2'>{rules.rule}:</span>
                                                <span>{rules.choice}</span>
                                                <span>{rules.operator}</span>
                                                <span>{rules.freeform}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ))}

                        </div>
                        {/* <ul className='border w-full min-h-[280px] ' >
                            {Object.entries(ruleBook).map((rule, index) => (
                                (rule!=='success'&&rule!=='add_gaps'&&rule!=='message')&&
                                    <li 
                                        onClick={() => chooseRule(rule[1])} 
                                        key={`${index}: ${rule}`}
                                        className={`w-full border-b px-6 py-4 text-left hover:bg-primary-dark hover:text-white hover:cursor-pointer 
                                            ${rule[1]===constraint.setting.rule?"bg-primary-dark text-white":""} `}>
                                        {rule[0]}
                                    </li>
                                ))
                            }
                        </ul> */}
                        <div className='flex gap-3 pt-6'>
                            <select value={constraint.setting.type}  onChange={chooseConstraints} name="type" className={dropDownStyle}>
                                <option>
                                    -- choose one --
                                </option>
                                {/* {Object.keys(stats).map((type, index) => (
                                    (type!=='success'&&type!=='add_gaps'&&type!=='message')&&
                                        <option key={`${index}: choice ${type}`} value={type}>
                                            {type}
                                        </option>
                                ))} */}
                                {pageData.claims_columns.map((type, index) => (
                                    (type!=='success'&&type!=='add_gaps'&&type!=='message')&&
                                        <option key={`${index}: choice ${type}`} value={type}>
                                            {type}
                                        </option>
                                ))}
                                {pageData.pmt_columns.map((type, index) => (
                                    (type!=='success'&&type!=='add_gaps'&&type!=='message')&&
                                        <option key={`${index}: choice ${type}`} value={type}>
                                            {type}
                                        </option>
                                ))}
                                
                            </select>
                            <select value={constraint.setting.operator} onChange={chooseConstraints} name='operator' className={dropDownStyle}>
                                <option>
                                    -- choose one --
                                </option>
                                {/* <option value='between'>
                                    between
                                </option>
                                <option value='not_between'>
                                    not between
                                </option> */}
                                <option name='equal_to' value="equal_to">
                                    equal to
                                </option>
                                <option name='not_equal_to' value="not_equal_to">
                                    not equal to
                                </option>
                                <option name='greater_than' value="greater_than">
                                    greater than
                                </option>
                                <option name='less_than' value="less_than">
                                    less than
                                </option>
                                <option name='greter_than_or_equal_to' value="greter_than_or_equal_to">
                                    greter than or equal to
                                </option>
                                <option name='less_than_or_equal_to' value="less_than_or_equal_to">
                                    less than or equal to
                                </option>
                            </select>
                            <input value={constraint.setting.freeform} name='freeform' onChange={chooseConstraints} className='w-full border py-3 px-2 min-w-[400px] ' />
                            <button type='submit' className='bg-light-blue hover:bg-hover-blue text-white px-2 font-bold'>
                                +
                            </button>
                        </div>
                        <div className='flex gap-20'>
                            <button type='button' onClick={resetAll} className={buttonStyles}>
                                reset rules
                            </button>
                            <button type='button' onClick={clearTemp} className={buttonStyles}>
                                clear temp
                            </button>
                            <button type='button' onClick={saveChoices} className={buttonStyles}>
                                Save
                            </button>
                        </div>
                    </>:<div className='flex justify-center items-center h-full w-full'>select a data set</div>}
                </form>
            </PopUpSection>
        </>
    );
};