import React, { useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import XButton from '../Buttons/XButton';
import { globalBoolHandler, handleCustomListChange } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';
import FormButton from '../Buttons/FormButton';
import { UseContextClues } from '../../context/ContextClues';
import { runMultiFormulary } from '../../utils/resultsSummary';
import { downloadOptimized } from '../../utils/download';
import Select from 'react-select'
import { FixedSizeList } from "react-window";
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';

export default function MuiltiFormulary() {
    
    const toggler = UseToggleContext();
    const context = UseContextClues();

    const [ chosenSettings, chooseSettings ] = useState({
        formularySelection: {
            selections: []
        },
        baseMidPt: 19584,
        year1Mid: 20268,
        annualization: 2,
        multiFormularyList: null,
        loading: false
    });

    function handleMultiSubmit(e) {
        e.preventDefault();
        var payload = {
            formularyId: chosenSettings.formularySelection.selections,
            parameters: context.exclusionSettings 
        }
        runMultiFormulary(payload, chosenSettings.baseMidPt, chosenSettings.year1Mid, chosenSettings.annualization, context.casenumber, chooseSettings)
    };

    function xOutPanel() {
        globalBoolHandler("multiFormulary", toggler.toggle, toggler.setToggle, false)
    };
    HandleKeydownEscape(xOutPanel);
    

    const lineStyles = 'w-full h-full flex items-center px-3'
    const buttonStyles = 'px-20 py-3 border bg-light-blue hover:bg-hover-blue text-white font-bold rounded-lg'

    const multiFormulary = ({data, index, style,}) => {
        return ( 
            <div key={`${index}: specialty sweeper data ${data[index]}`} style={style} className={`${index%2===0?"bg-gray-400":" "} flex bg-background-light border w-full items-center`}>
                    <div key={`${index}: multi formulary id ${data[index].formularyId} drugname ${data[index].drugname}`} className={'flex items-center w-full h-full'}>
                        <div className={`${lineStyles} border`}>
                            {data[index].formularyId}
                        </div>
                        <div className={`${lineStyles} border whitespace-pre-wrap`}>
                            {data[index].drugname}
                        </div>
                        <div className={`${lineStyles} border  whitespace-pre-wrap`}>
                            {data[index].rate1}
                        </div>
                        <div className={`${lineStyles} border  whitespace-pre-wrap`}>
                            {data[index].rebates1}
                        </div>
                    </div>
                
            </div>
        );
    };

    return (
        <>
            {!chosenSettings.multiFormularyList?<PopUpSection>
                <div className='relative bg-white w-[400px] h-full mx-auto'>
                    <XButton additionalClasses={'mt-3'} clickHandler={() =>xOutPanel()} />
                    <form onSubmit={handleMultiSubmit} className='p-8'>
                        <label><span className='tracking-widest'>Formulary selections </span>
                            <Select 
                                isMulti
                                options={context.formularies.map((formulary, idx) => ({
                                    value: `${formulary.formularyid}`,
                                    label: `${idx+1}: ${formulary.formularyid}`,
                                    toString: () => formulary.formularyid
                                }))}
                                required
                                onChange={e => handleCustomListChange(e, 'selections', chooseSettings, null, "formularySelection")}
                                menuShouldScrollIntoView={false}
                                name='multiFormulary'
                                className='mt-1'
                                menuPosition='fixed'
                                // value={defaultList.constraint}
                                menuPortalTarget={document.body} 
                                styles={{ menuPortal: base => ({ ...base, zIndex: 700, fontSize: "x-small", }) }}
                            />
                            <FormButton buttonText={'run multi'} />
                        </label>
                    </form>
                </div>    
            </PopUpSection>:
            <PopUpSection>
                <div className='flex items-center justify-center '>
                    <div className='px-20'>
                        <div style={{width:toggler.pageWidth-800}} className='flex border h-[60px] bg-background-light font-bold capitalize tracking-widest rounded-t-xl'>
                            {Object.keys(chosenSettings.multiFormularyList[0]).map((headers, idx) => (
                                <div key={`${idx} specialty sweeper headers ${headers}`} className={lineStyles}>
                                    {headers.split("_").join(" ")}
                                </div>
                            ))}
                        </div>
                        <FixedSizeList
                            height={toggler.pageHeight-400}
                            width={toggler.pageWidth-800}
                            initialScrollOffset={true}
                            itemCount={chosenSettings.multiFormularyList?.length}
                            itemSize={50} // Height of each row
                            itemData={chosenSettings.multiFormularyList}
                            >
                                {multiFormulary}   
                        </FixedSizeList>  
                        <div className='bg-primary-dark rounded-b-xl h-[80px] flex justify-between gap-[60%] p-4'>
                            <FormButton buttonText={'cancel'} marginTop={'mt-0'} onClick={() => xOutPanel()} className={buttonStyles} />
                            <FormButton onClick={() => downloadOptimized(context.casenumber, chooseSettings) } loading={chosenSettings.loading} buttonText={'download'}  marginTop={'mt-0'} className={buttonStyles} />
                        </div>
                    </div>
                </div>
            </PopUpSection>}
        </>
    );
};