import React, { createContext, useContext, useState } from 'react';
import { globalBoolHandler } from '../utils/globalHandlers';
import { useIdleTimer } from 'react-idle-timer'
import { extractFromLocal } from '../utils/loadSave';

const ToggleContext = createContext({});

export default function ToggleContextProvider({children}) {

  const [ loader, setLoader ] = useState("");
  const [ status, setStatus ] = useState("");

    // collapsible for auto features
    const [ collapsible, setCollapsible ] = useState({
        claimsControl: false,
    });

    const [ pageHeight, setHeight ] = useState(window.innerHeight);
    const [ pageWidth, setWidth ] = useState(window.innerWidth);
    const [ dateRange, setDateRange ] = useState({
        location: {
            pageX: 0,
            pageY: 0
        },
        data: [],
        dates: {
          min: null,
          max: null,
        },
        focus: false
    });
    const [ toggle, setToggle ] = useState({
        channelDesignationPanel: false, 
        importWindow: false,
        helpWindow: false,
        breakEven: false,
        loadSave: true,
        saveLoad: false,
        showTime: false,
        auxLogin: false,
        importFormulary: false, 
        mobileSidePanel: true,
        countiesMap: false,
        saveMemo: false,
        pipelineEvents: false,
        userDefinedLists: false,
        timeOut: false,
        orgSettings: false,
        myFiles: false,
        tableManagement: false,
        exludeHedgeFromMargin: true,
        importPayment: false, 
        createVendorPricingExtract: null,
        applyAllEventsToProject: true,
        medispanAdmin: false,
        rebateCredit: false,
        copyFormularyNewOrg: false,
        multiFormulary: false
    });
    const [ aux, switchAux ] = useState('');
    const [ isLoading, setLoading ] = useState(undefined);

    const [ actionCounter, setCounter ] = useState(0);

    const onIdle = () => {
        updateState('Idle')
        globalBoolHandler("timeOut", toggle, setToggle, false);
    };
    
    const onActive = () => {
        updateState('Active')
        globalBoolHandler("timeOut", toggle, setToggle, false);
      };
    
    const onAction = () => {
        setCounter(actionCounter + 1);
    };
  
    const onPrompt = () => {
      globalBoolHandler("timeOut", toggle, setToggle, true);
      updateState('Warning')
    };

    const [ userActivity, updateState ] = useState('active');


    const [timer, setTimer] = useState();
    const startTime = new Date(extractFromLocal()?.loggedInAt).getTime();
    var currentTime = new Date().toLocaleString("en-US", {hourCycle: "h24", timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    // console.log(currentTime)
    currentTime = new Date(currentTime).getTime();
    // console.log(currentTime-startTime)
    // console.log(((currentTime-startTime) < 1800000)&&((currentTime-startTime) > 1500000))


    const { activate } = useIdleTimer({
        onIdle,
        onAction,
        onActive,
        onPrompt,
        promptBeforeIdle: 300_000,
        timeout:  900_000,
        throttle: 500
    });

    const toggleProps = {
        setLoading,
        isLoading,
        setHeight, 
        pageHeight,
        setWidth,
        pageWidth, 
        switchAux,
        aux,
        setLoader, 
        loader,
        setStatus,
        status,
        setCollapsible,
        collapsible,
        
    
        setToggle,
        toggle,
        // these may be temp
        dateRange, 
        setDateRange,
        
        activate,
        userActivity,
        // getRemainingTime,
        // activate,
        // setRemaining,
    
      };
  
    return <ToggleContext.Provider value={toggleProps}>{children}</ToggleContext.Provider>
};

export const UseToggleContext = () => useContext(ToggleContext);
