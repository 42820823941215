import React, { useState } from 'react';
import { UseContextClues } from '../context/ContextClues';
import { globalChangeHandler, handleGlobalToggle } from '../utils/globalHandlers';
import { fuzzySweeper, specialtySweeper } from '../utils/qualityAssurance';
import FormButton from '../components/Buttons/FormButton';
import SpecialtySweeper from '../components/QualityAssurance/SpecialtySweeper';
import FuzzySweeper from '../components/QualityAssurance/FuzzySweeper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import CustomHover from '../components/Hover/CustomHover';
import Logo259 from '../components/SVG/Logo259';
import { UseToggleContext } from '../context/ToggleContexts';
import TailChaseLoader from '../components/Loader/TailChaseLoader';

export default function QualityAssurance() {

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ inputs, set ] = useState({
        specialty : "",
        specialtyData : [],
        fuzzyOrg : "",
        fuzzyFormulary : "",
        fuzzyData : [],
        hoverSpecial: false,
        hoverFuzzy: false,
        loadSpecial: false,
        loadFuzzy: false
    });

    const labelStyles = "flex flex-col border p-6 mb-10 tracking-widest bg-background-light rounded";
    const inputStyles = "flex flex-col border p-4 mt-2 w-full "; 
    const rowStyles = "w-full border  p-3 h-full "; 

    function handleSweeperChange(e) {
        if (e.target.value) {
            specialtySweeper(e.target.value, set);
        };
    };

    function handleFuzzySweeper(e) {
        e.preventDefault();
        fuzzySweeper(inputs.fuzzyOrg, inputs.fuzzyFormulary, set)
    };

    return (
        <>
            <div className='flex h-[100vh]'>
                <div className='border p-10 w-[420px] bg-primary-dark'>
                    <h2 className='text-white text-2xl mb-8'>
                        Quality Assurance Panel
                    </h2>
                    <div className={labelStyles}>
                        <span className='flex justify-between'>
                            <div>Specialty Sweeper</div>  
                            <FontAwesomeIcon onMouseEnter={() => handleGlobalToggle(set, "hoverSpecial", false)} onMouseLeave={() => handleGlobalToggle(set, "hoverSpecial", true)} className={'text-xl text-light-blue hover:text-hover-blue'} icon={faCircleInfo} />    
                        </span>
                        <label className='pt-4'>Select from specialty list:
                            <select name='specialty' onChange={handleSweeperChange} className={inputStyles}>
                                <option value={''}>
                                    --- Choose one ---
                                </option>
                                {context.models.modelList?.specialty_lists.map((listname, idx) => (
                                    <option key={`${idx}: specialty model ${listname}`} value={listname}>
                                        {listname}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <form onSubmit={handleFuzzySweeper} className={labelStyles}>
                        <span className='flex justify-between'>
                            <div>
                                Fuzzy Rate Chart Sweeper
                            </div>  
                            <FontAwesomeIcon onMouseEnter={() => handleGlobalToggle(set, "hoverFuzzy", false)} onMouseLeave={() => handleGlobalToggle(set, "hoverFuzzy", true)} className={'text-xl text-light-blue hover:text-hover-blue'} icon={faCircleInfo} />    
                        </span>
                        <label className={'mt-10'}>Organization:
                            <input required name='fuzzyOrg' onChange={(e) => globalChangeHandler(e, null, set)} className={inputStyles} />
                        </label>
                        <label className={''}>Formulary:
                            <select onChange={e => globalChangeHandler(e, null, set)} name='fuzzyFormulary' required className={inputStyles}>
                                <option value={""}>
                                    --- Choose one ---
                                </option>
                                {context.formularies.map((forms, idx) => (
                                    <option key={`${idx}: formulary model ${forms.formularyid}`}>
                                        {forms.formularyid}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <FormButton buttonText={'run'} />
                    </form>
                </div>
                <div className='border bg-primary-dark w-full'>
                    {inputs.specialtyData.length>0?<div className='border p-4'>
                        <SpecialtySweeper {...{ inputs, rowStyles }} />
                        </div>:
                        <div style={{height: (toggler.pageHeight-131)/2}} className='flex items-center justify-center border'>
                            {inputs.loadSpecial&&inputs.specialtyData.length===0?<TailChaseLoader size={'100'} color={"white"} />
                            :<Logo259 className={" h-[200px] w-[200px]"} />}
                    </div>}

                    {inputs.fuzzyData.length>0?<div className='border p-4'>
                            <FuzzySweeper {...{ inputs, rowStyles }} />
                        </div>:
                        <div style={{height: (toggler.pageHeight-131)/2}} className='flex items-center justify-center border'>
                            {inputs.loadFuzzy&&inputs.specialtyData.length===0?
                                <TailChaseLoader size={'100'} color={"white"} />
                            :<Logo259 className={" h-[200px] w-[200px]"} />}
                    </div>}
                </div>
                <CustomHover {...{alternativeBot:" hidden", hovered:inputs.hoverSpecial, left:"left-[365px]", top: "top-[200px]", width:"max-w-[400px]", text:"text-sm text-center" }}  >
                    Analyzes an existing specialty list for potential missing products to add to the list. Sweeps for two match types, exact and fuzzy. Exact matches are non-existing NDCs for existing drug names on the list. Fuzzy matches are suggestions for drug names (along with corresponding NDCs) to add based on existing similarly-named drugs. The similarly-named drugs are provided for reference.
                </CustomHover>
                <CustomHover {...{hovered:inputs.hoverFuzzy, left:"left-[365px]", top: "top-[420px]", alternativeBot:'hidden', width:"max-w-[400px]", text:"text-sm text-center" }}  >
                    Analyzes a formulary rate chart for missing drug names that may qualify for a rate, and suggests a rate based on associated existing drug names with that rate.
                </CustomHover>
            </div>
        </>
    );
};